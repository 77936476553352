import React, { useState, useRef } from 'react';
import { setAdminToken } from '../../../../Utils/Common';
import jwt_decode from "jwt-decode";
import oceanapi, { loginAdmin } from '../../../../Base/OceanConfig';
import Message from '../../../../Components/Message/Message';
import ReCAPTCHA from 'react-google-recaptcha';
import style from '../../signup.module.css';

export default function Login(props){
    const captchaRef = useRef(null);
    const [ email, setEmail] = useState('');
    const [ password, setPassword] = useState('');
    const [ loading, setLoading ] = useState(false);
    const [ message, setMessage ] = useState('');
    const [ messageClass, setMessageClass ] = useState('');

    const saveUserName = (x) => sessionStorage.setItem("userName", x);
    // const saveSubdomain = (x) => sessionStorage.setItem("subdomain", x);

    const adminloginDetail = (e) => {
        e.preventDefault();
        const token = captchaRef.current.getValue();
        setLoading(true);
        oceanapi.post(loginAdmin, {
            email: email,
            password: password,
            recaptchaToken: token
        })
        .then((res) => {
            setLoading(false);
            if(res.data.code === 200){
                saveUserName(jwt_decode(res.data.token).userName);
                // saveSubdomain(res.data.result.subdomain);
                setAdminToken(res.data.token);
                if(res.data.result?.subdomain){
                    props.checkSubDomainFunction();
                } else {
                    props.changeStep(2);
                }
            } else {
                setMessageClass('error');
                captchaRef.current.reset();
                setMessage(res.data.message);
                setTimeout(() => {
                    setMessage('')
                }, 5000);
            }
        })
        .catch((error) => {
            console.error(error);
            setLoading(false);
            captchaRef.current.reset();
            setMessageClass('error');
            setMessage("Something went wrong, Try again!.");
            setTimeout(() => {
                setMessage('')
            }, 5000);
        })
    }
    
    return (<>
        {message && <Message message={message} messageClass={messageClass} closeMessage={() => setMessage('')} />}
        <div className={style['basic-details']}>
            <h5 className={style['step-heading']}>Login details</h5>
            <form onSubmit={adminloginDetail}>
                <div>
                    <label>Email</label>
                    <input type="text" placeholder='Enter email' value={email} onChange={e => setEmail(e.target.value)} required/>
                </div>
                <div>
                    <label>Password</label>
                    <input type="text" placeholder='Enter password' value={password} onChange={e => setPassword(e.target.value)} required/>
                </div>
                <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY} ref={captchaRef} />
                {/* <div></div> */}
                <div className={style['signup-button']}>
                    {
                        loading
                        ?<button className='btn primary-button' disabled>Wait...</button>
                        :<button type="submit" className='btn primary-button'>Next <img src="./images/arrow.svg" alt="arrow" /></button>
                    }
                </div>
            </form>
        </div>
    </>);
}