import axios from "axios"
export const baseurl = process.env.REACT_APP_CONFIG_URL

const api = axios.create({
    baseURL: baseurl,
    headers: {
        token: process.env.REACT_APP_TOKEN,
        versionnumber: process.env.REACT_APP_VERSION,
    },
})

export default api
